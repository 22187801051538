import styled from '@emotion/styled'
import { CheckCircle } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  list?: string[]
  title?: string
}

export const Benefits = memo(function Benefits({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
      {list.map((item, index) => (
        <Item dial={4} key={index} row>
          <CheckCircle />
          {
            //@ts-ignore
            item.label
          }
        </Item>
      ))}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  margin-top: 4.125rem;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 2rem;

  br {
    content: ' ';
    display: inline;
    padding: 0 0.1563rem;
  }
`

const Item = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
  margin-top: 1.5rem;

  svg {
    width: auto;
    height: 1.25rem;
    fill: none;
    margin-right: 0.75rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight3};
    stroke-width: 2;
  }
`
