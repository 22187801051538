import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  discount?: string
  validity?: string
}

export const Head = memo(function Head({ discount, validity }: Props) {
  if (!discount || !validity) {
    return null
  }

  return (
    <Container dial={5} row stretch wrap>
      {validity ? (
        <LeftSide dial={5} row wrap>
          {validity ? <Validity>{validity}</Validity> : null}
        </LeftSide>
      ) : null}
      {discount ? (
        <RightSide>
          <Discount>{discount}</Discount>
        </RightSide>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  text-align: center;

  > div {
    flex: 1;
    padding: 3.3125rem 3.333vw 3rem;
  }

  @media (max-width: 1023px) {
    > div {
      flex: auto;
      padding: 1.875rem;
    }
  }
`

const LeftSide = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0094rem;
  line-height: 1.1875rem;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
  }
`

const Validity = styled.div``

const RightSide = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryLight3};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.4375rem;
  }
`

const Discount = styled.div``
